.ql-align-right {
  text-align: right;
}

.ql-align-center {
  text-align: center;
}

.ql-align-justify {
  text-align: justify;
}

.ql-indent-1 {
  padding-left: 3em;
  list-style-position: inside;
}

.ql-indent-2 {
  padding-left: 6em;
  list-style-position: inside;
}

.ql-indent-3 {
  padding-left: 9em;
  list-style-position: inside;
}

.ql-indent-4 {
  padding-left: 12em;
  list-style-position: inside;
}

.ql-indent-5 {
  padding-left: 15em;
  list-style-position: inside;
}

.ql-indent-6 {
  padding-left: 18em;
  list-style-position: inside;
}

.ql-indent-7 {
  padding-left: 21em;
  list-style-position: inside;
}

.ql-indent-8 {
  padding-left: 24em;
  list-style-position: inside;
}

.blot-formatter__toolbar-button {
  display: none !important;
}
