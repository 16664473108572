@import '../../../../assets/scss/_functions.scss';
@import '../../../../assets/scss/_variables.scss';
@import '../../../../assets/scss/_mixins.scss';

.mobileReportItem {
  &Title {
    font-weight: bold;
    font-size: 18px;
  }
  &Modal {
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 12px;
    border-radius: 3px;
    min-width: 291px;
  }
}
