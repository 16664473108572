@import '../../assets/scss/functions';
@import '../../assets/scss/variables';
@import '../../assets/scss/mixins/';

.container {
  pointer-events: none;
}

.barWrapper {
  background: $red-500;
  height: 4px;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1031;
}

.bar {
  box-shadow: 0 0 10px $red-500, 0 0 5px $red-500;
  display: block;
  height: 100%;
  opacity: 1;
  position: absolute;
  right: 0;
  transform: rotate(3deg) translate(0px, -4px);
  width: 100px;
}
