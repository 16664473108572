.initials,
.initialsSmall {
  background-color: #eee;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 3rem;
}
.initialsSmall {
  width: 40px;
  height: 40px;
  line-height: 2.5rem;
}
