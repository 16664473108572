@import '../../assets/scss/functions';
@import '../../assets/scss/variables';
@import '../../assets/scss/mixins/';

.filesButtonLink {
  background-color: $gray-50;
  border: solid 1px $gray-50;
  border-radius: $border-radius;
  color: $gray-500;
  font-size: $font-size-sm;
  padding: $spacer-xs $spacer-md;
  display: inline-grid;
  grid-template-columns: 32px calc(100% - 32px - #{$spacer-sm});
  grid-gap: $spacer-sm;
  align-items: center;
  margin-bottom: $spacer-sm;
  width: 100%;
  height: 50px;

  @include media-breakpoint-up(md) {
    width: auto;
  }

  &:hover {
    color: $gray-500;
    text-decoration: none;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
  }

  &:focus {
    border-color: $gray-400;
    outline: none;
  }

  &.bgWhite {
    background-color: $white;
  }
}

.filesButtonLinkIcon {
  background-color: $white;
  margin-right: $spacer-sm;
  border-radius: 100%;
  width: 2rem;
  height: 2rem;
  position: relative;

  &.bgGray {
    background-color: $gray-50;
  }

  i {
    color: $red-500;
    font-size: 1.125rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
}

.filesButtonLinkLabel {
  color: $green-500;

  font-size: 0.875rem;
  font-weight: bold;
}

.files-button_link__icon_download {
  font-size: 1.5rem;
}

.files-button-link__label_medium {
  color: $gray-600;
}

.fileDelete {
  margin: 0 0 0 auto;
  color: $red-500;

  &:hover {
    color: $red;
  }
  text-align: center;
}
