@import '../../assets/scss/_functions.scss';
@import '../../assets/scss/_variables.scss';
@import '../../assets/scss/_mixins.scss';

.newsListItemDescription {
  color: $gray-500;
  font-size: 0.875rem;
  line-height: 1.2rem;
  min-height: 3.6rem;
  &--max-height {
    max-height: 3.6rem;
    overflow: hidden;
  }
}

.newsListItemDescription p {
  margin: 0;
}

.newsListItemImage {
  background-position: top left;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;

  span {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.newsListItemInformation {
  min-width: 0;
  padding: 1rem;
  padding-bottom: 0;
}

.newsListItemLink {
  border: none;
  padding: 0;
}

.newsListItemLink:focus {
  &::after {
    border: solid 2px $gray-400;
    border-radius: 0.375rem;
  }
}

.newsListItemTitle {
  color: $gray-700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.horizontalItem {
    font-size: 1rem;
  }
}

.newsListItemWrapper:not(:last-child) {
  margin-bottom: 0.5rem;
}

.newsListItemWrapperInner.active {
  border-right: 4px solid $red-500;

  .newsListItemLink:focus {
    &::after {
      border: solid 2px $gray-400;
      border-radius: 0;
      border-bottom-left-radius: 0.375rem;
      border-top-left-radius: 0.375rem;
      border-right: 0.1px solid transparent;
    }
  }
}

.newsListItemWrapperInner {
  border: none;
  border-radius: 0.375rem;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: $white;
  display: flex;
  flex-flow: row;
  height: 100%;
  overflow: hidden;
  width: 100%;
}

.newsListItemDescription {
  min-height: 4.6rem;
}
.newsListItemWrapper.large {
  .newsListItemWrapperInner {
    flex-flow: column;
  }

  .newsListItemImage {
    height: 0;
    padding-top: 56.25%;
    width: 100%;
    background-color: $gray-75;
  }
}

.newsListItemWrapper.highlighted.large.fixedHeight {
  .newsListItemWrapperInner {
    height: 22.11125rem;
  }
}

.highlighted-news {
  .news-list-item_link:focus {
    &::after {
      border-right: solid 2px $gray-400 !important;
    }
  }
}

.newsListItemWrapperInner.editorNote {
  border: 2px solid $yellow-300;
}

.fadingEffect {
  left: 0px;
  bottom: 0px;
  right: 0px;
  height: 50px;
  position: absolute;
  background: linear-gradient(rgba(255, 255, 255, 0), white);
}
