@import '../../assets/scss/functions';
@import '../../assets/scss/variables';
@import '../../assets/scss/mixins/';
@import '../../assets/scss/buttons';

$icon-size: 1.5rem;

.devControl {
  @include border-radius();
  font-family: $font-family-base;
  z-index: 20;
  bottom: 24px;
  left: 24px;
  max-height: calc(100vh - 96px - #{$spacer-lg});
  overflow-y: auto;
  font-size: 0.875rem;
  position: fixed;
  background-color: $body-bg;
  padding: 0;
  color: $green-700;
}

.devControlToggle {
  z-index: 23;
  position: sticky;
  bottom: 0;
  width: calc(2 * #{$spacer-lg} + 100%);
  margin-left: -$spacer-lg;
  background-color: $body-bg;
  border: 0;
  padding: $spacer-sm $spacer-lg;
  text-align: left;
  font-size: $font-size-base;
  font-weight: bold;

  &:not(:first-child) {
    border-top: 1px solid $gray-200;
  }
}

.devControlToggleButton {
  width: 100%;
  background: transparent;
  color: $green-300;
  text-align: left;
  font-weight: bold;
  font-size: $font-size-xs;
  font-style: italic;
  padding: $spacer-sm 0 0;
  margin: $spacer-sm 0 0;
  border: 0;
  border-top: 1px solid $green-300;
  transition: color 0.2s, border-top-color 0.2s;

  .memberMeta:hover & {
    color: $white;
    border-top-color: $gray-200;
  }

  &.alone {
    border: 0;
    margin-top: 0;
    padding-top: 0;
  }

  &:active,
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.memberMeta {
  position: sticky;
  bottom: 0;
  z-index: 2;
  cursor: pointer;
  background: $green-700;
  color: $gray-50;
  padding: $spacer $spacer-lg;
  box-shadow: 0px 0px 4px $green-700;
  font-style: italic;
  font-size: $font-size-xs;

  .memberMetaDivider {
    border-bottom: 3px solid $green-700;
  }
}

.memberName {
  margin-bottom: 0;

  &:after {
    content: '';
    clear: both;
    display: table;
  }

  .memberNameDivider & {
    border-bottom: 1px solid $green-300;
    margin-bottom: $spacer-sm;
    padding-bottom: $spacer-sm;
  }
}

.gridList {
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: $spacer-xs;
}

.h2 {
  font-weight: bold;
  font-size: 1rem;
}

.h3 {
  margin-top: $spacer-sm;
  font-size: $font-size-sm;
  margin-bottom: 0;
}

.itemList {
  list-style: none;
  margin: $spacer-sm 0 0;
  padding: 0;
}

.listItemButton {
  @extend .btn;
  @extend .btn-sm;
  @extend .btn-gray-50;
  display: grid;
  grid-template-columns: $icon-size 1fr;
  gap: $spacer-sm;
  text-align: left;
  margin-bottom: $spacer-xs;
  width: 100%;

  &.selected {
    background-color: $green-300;
    border-color: $green-500;
    color: $white;
  }
}

.flagIcon {
  font-style: normal;
  margin-right: $spacer-sm;
  display: inline-flex;
  justify-content: center;
  padding: $spacer-sm;
  align-items: center;
  width: $icon-size;
  height: $icon-size;
  background-color: rgba($white, 0.7);
  border-radius: 1rem;
  box-shadow: 0px 0px 1px 1px rgba($white, 1),
    0px 0px 2px rgba($green-300, 1) inset;

  :local(.memberName) & {
    margin: -5px 0 0px;
  }
}

.icon {
  :local(.memberName) & {
    float: right;
  }
  &.withMemberName {
    margin-left: $spacer;
  }
}

.tinyOptionsList {
  @include list-unstyled();
}

.featuresList {
  list-style: none;
  margin-left: 0;
  padding: $spacer-xs;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: $spacer-xs;
  background-color: $gray-25;
  border-radius: 3px;
}

.featureItem {
  font-size: $font-size-xs;
  background-color: $white;
  padding: $spacer-xs;
  border-radius: 3px;

  &.inActive {
    color: $gray-300;
    :global(.far) {
      color: $red-300;
    }
  }
  :global(.far) {
    color: $green;
    display: inline-block;
    margin-right: $spacer-xs;
  }
}

.userMemberFallback {
  min-height: 128px;
  position: relative;
}
