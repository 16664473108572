@import '../../../../assets/scss/_functions.scss';
@import '../../../../assets/scss/_variables.scss';
@import '../../../../assets/scss/_mixins.scss';

.eventsSubMenuTab {
  background-color: transparent;
  border: none;
  color: $gray-400;
  display: block;
  font-size: 0.875rem;
  font-weight: bold;
  padding: 0.5rem 0.75rem;
  width: 100%;

  @include media-breakpoint-up(md) {
    width: auto;
  }
}

.eventsSubMenuTab:hover {
  background-color: transparent;
  border: none;
  color: $gray-700;
  text-decoration: none;
}

.eventsSubMenuTab:focus,
.eventsSubMenuTab[aria-selected='true'] {
  background-color: transparent;
  border: none;
  color: $gray-700;
  outline: none;
}

.eventsSubMenuTab[aria-selected='true']:hover {
  color: $gray-500;
}

.eventsSubMenuTab[aria-selected='true']:focus {
  text-decoration: underline;
}

.eventSubMenuActiveMarkerWrapper {
  border-bottom: solid 1px $gray-100;
}

.eventSubMenuActiveMarker {
  background-color: $red-500;
  height: 5px;
  transition: 0.4s ease-in-out;
}

.eventSubMenuWrapper {
  [role='tabpanel'][aria-hidden='true'] {
    display: none;
  }
  @include media-breakpoint-up(md) {
    margin-bottom: 2rem;
  }
}
