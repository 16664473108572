@import '../../assets/scss/_functions.scss';
@import '../../assets/scss/_variables.scss';
@import '../../assets/scss/_mixins.scss';

.filterSelectorWrapper {
  position: relative;
}

.filterSelectorInput {
  background-color: $white;
  border: solid 1px $white;
  border-radius: 0.375rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  color: $gray-500;
  display: flex;
  height: calc(1.5em + 0.75rem + 4px);
  justify-content: space-between;
  padding: 0.375rem 0.75rem;
  position: relative;
  width: 100%;
}

.filterSelectorInput:focus {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  color: $gray-700;
  outline: none;
}

.filterSelectorInputWrapper {
  position: relative;
}

.filterSelectorToggleIcon {
  color: $gray-500;
  cursor: pointer;
  padding: 0.5rem;
  position: absolute;
  right: 0.25rem;
  top: 0.25rem;
  z-index: 3;
}

.filterSelectorListWrapper {
  background-color: $gray-50;
  border-top: solid 1px $gray-100;
  max-height: 70vh;
  overflow-y: scroll;
  position: absolute;
  scroll-behavior: smooth;
  scroll-padding: 1rem 0;
  width: 100%;
  z-index: 2;
}

.filterSelectorListSelected {
  color: $gray-500;
  display: flex;
  font-size: 0.875rem;
  justify-content: space-between;
  padding: 0.5rem 0.75rem;
}

.filterSelectorClearSelected {
  background-color: $white;
  border: solid 1px $gray-500;
  border-radius: 0.25rem;
  color: $gray-700;
  font-weight: bold;
  line-height: 1rem;
  padding: 0 0.5rem;
  text-transform: uppercase;
}

.filterSelectorClearSelected:focus {
  outline: none;
}

.filterSelectorClearSelected:hover {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}

.filterSelectorList {
  list-style: none;
  margin: 0;
  padding: 0.5rem 0.5rem 0.75rem;
}

.filterSelectorOption {
  background-color: $white;
  border: solid 1px $white;
  border-radius: 0.25rem;
  color: $gray-700;
  cursor: pointer;
  display: flex;
  height: calc(1.5em + 0.75rem + 4px);
  justify-content: space-between;
  line-height: 1.25rem;
  padding: 0.5rem 0.75rem;

  &:hover {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  }

  &[aria-selected='true'] {
    border-color: $gray-700;
  }

  &:active {
    background-color: $red-500;
    border-color: $red-500;
    color: $white;
  }
}

.filterSelectorOption:not(:last-child) {
  margin-bottom: 0.5rem;
}

.filterSelectorPption.selected {
  background-color: $red-500;
  border-color: $red-500;
  color: $white;

  &:hover {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  }

  &[aria-selected='true'] {
    border-color: $gray-700;
  }

  &:active {
    background-color: $white;
    border-color: $white;
    color: $gray-700;
  }
}

.filterSelectorWrapper.open {
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.3);

  .filterSelectorInput {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: none;
    z-index: 3;
  }

  .filter-selector_badge {
    z-index: 4;
  }

  .filter-selector_list__wrapper {
    border-bottom-left-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.3);
    display: block;
  }
}
