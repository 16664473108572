@import 'src/assets/scss/_functions.scss';
@import 'src/assets/scss/_variables.scss';
@import 'src/assets/scss/_mixins.scss';


.message {
  padding: $spacer-xs;
  display: block;
  font-size: $font-size-sm;
  @include media-breakpoint-up(sm) {
    padding: $spacer-sm $spacer-lg;
    width: calc(100% - 18px);
    display: inline-block;
    vertical-align: middle;
  }
}

.icon {
  padding: $spacer-sm $spacer-lg;
  display: block;
  text-align: center;
  @include media-breakpoint-up(sm) {
    padding: 0;
    display: inline-block;
    vertical-align: middle;
    width: 18px;
  }
}
.close {
  position: absolute;
  top: 0;
  right: 0;
  padding: $spacer;
  line-height: 1;
  color: inherit;
  border: 0;
  background-color: transparent;
  font-size: $font-size-lg;
  margin-top: -#{$spacer};
  margin-right: -#{$spacer};
  @include media-breakpoint-up(sm) {
    margin-right: -#{$spacer-sm};
  }
}

.wrapper {
  position: relative;
}
