@import 'src/assets/scss/_functions.scss';
@import 'src/assets/scss/_variables.scss';
@import 'src/assets/scss/_mixins.scss';
@import 'src/assets/scss/_navbar.scss';

.desktopMenuWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: $spacer-sm 0;
  @include media-breakpoint-up(xl) {
    flex-direction: row;
  }
}

.desktopMenuLogo {
  margin-bottom: -#{$spacer-xs};
  padding-top: $spacer-xs;
  @include media-breakpoint-up(xl) {
    margin-bottom: 0;
    padding-top: 0;
    transform: translateY(-2px);
  }
}

.desktopMenuNavbar {
  @extend .navbar;
  width: 100%;
  padding: 0;

  @include media-breakpoint-up(xl) {
    transform: translateY(1px);
    flex-grow: 1;
  }
}
