@import '../../assets/scss/functions';
@import '../../assets/scss/variables';
@import '../../assets/scss/mixins/';

.contactTileCard {
  border: none;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  font-size: 0.875rem;
  overflow: hidden;
}

.contactTileImage {
  height: 2.125rem;
  width: 2.125rem;
  border-radius: 50%;
  object-fit: cover;
  object-position: center center;
  margin-right: 0.5rem;
  margin-top: 0.125rem;
}

.contactTileNameWrapper {
  padding: 0.5rem;
  padding-right: 1rem;
}

.contactTileName {
  font-size: 0.875rem;
  font-weight: bold;
  margin: 0;
}

.contactTileContactWrapper {
  background-color: $gray-50;
  color: $gray-600;
  font-size: 0.875rem;
  line-height: 1.14rem;
  min-height: 2.28rem;
  padding: 0.25rem 2.5rem 0.25rem 3.125rem;
}
