@import 'src/assets/scss/_functions.scss';
@import 'src/assets/scss/_variables.scss';

.devControlSwitch {
  :global(.custom-control-input ~ .custom-control-label::before) {
    background-color: rgba($green-50, 0.5) !important;
  }
  :global(.custom-control-input:checked ~ .custom-control-label::before) {
    background-color: $green-500 !important;
  }
  :global(.custom-control-input:disabled ~ .custom-control-label) {
    opacity: 0.5;
  }
}
