/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@import 'functions';
@import 'variables';
@import 'mixins';
@import 'root';
@import 'reboot';
@import 'type';
@import 'images';
@import 'code';
@import 'grid';
@import 'tables';
@import 'forms';
@import 'buttons';
@import 'transitions';
@import 'dropdown';
@import 'button-group';
@import 'input-group';
@import 'custom-forms';
@import 'nav';
@import 'navbar';
@import 'card';
@import 'breadcrumb';
@import 'pagination';
@import 'badge';
@import 'jumbotron';
@import 'alert';
@import 'progress';
@import 'media';
@import 'list-group';
@import 'close';
@import 'toasts';
@import 'modal';
@import 'tooltip';
@import 'popover';
@import 'carousel';
@import 'spinners';
@import 'utilities';
@import 'print';
@import 'extended';
@import 'quill';
@import 'layout';

@import '../../components/ContactTile/ContactTile.module.scss';
@import '../../components/FilterSelector/FilterSelector.module.scss';
@import '../../components/FormInputs/FormInputs.module.scss';
@import '../../components/ListItem/ListItem.module.scss';
@import '../../components/SearchBar/SearchBar.module.scss';
@import '../../components/FilesButton/FilesButton.module.scss';
@import '../../components/LoadMoreButton/LoadMoreButton.module.scss';

@import '../../project/Events/components/EventDetailItem/EventDetailItem.module.scss';
@import '../../project/Events/components/EventYear/EventYear.module.scss';
@import '../../project/Events/components/Submenu/Submenu.module.scss';

@import '../../components/DetailItem/DetailItem.module.scss';
@import '../../components/CardListItem/ListItem.module.scss';

@import '../../project/Reports/components/ReportYear/ReportYear.module.scss';
@import '../../project/Reports/components/MobileReportItem/MobileReportItem.module.scss';

@import '../../project/Login/components/UserOnboarding/UserOnboarding.module.scss';

@import '../../components/NavMenu/NavMenu.module.scss';

.no-underline {
  text-decoration: none !important;
}

.p {
  font-size: 1rem;
}

.downloadButton {
  outline: none;
  background-color: $gray-50;
  color: $gray-500;
  border-radius: 3px;
  padding: 0.5rem 1rem;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0);
  font-weight: 600;
  white-space: nowrap;
  margin-left: 0.5rem;

  &:hover {
    background-color: $red-50;
    color: $red-700;
  }
  &:focus {
    background-color: $red-50;
    color: $red-700;
    border-color: $red-500;
  }
  &:active {
    background-color: $red-500;
    color: white;
    border-color: rgba(0, 0, 0, 0);
  }
  &:last-of-type {
    border-width: 2px;
  }
}
