// stylelint-disable declaration-no-important

.shadow-sm {
  box-shadow: $box-shadow-sm !important;
}
.shadow {
  box-shadow: $box-shadow !important;
}
.shadow-lg {
  box-shadow: $box-shadow-lg !important;
}
.shadow-none {
  box-shadow: none !important;
}

.shadow-1 {
  box-shadow: $shadow-1;
}

.shadow-2 {
  box-shadow: $shadow-2;
}

.shadow-3 {
  box-shadow: $shadow-3;
}

.shadow-4 {
  box-shadow: $shadow-4;
}

.shadow-5 {
  box-shadow: $shadow-5;
}
