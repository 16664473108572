@import 'src/assets/scss/_functions.scss';
@import 'src/assets/scss/_variables.scss';
@import 'src/assets/scss/_mixins.scss';
@import 'src/assets/scss/action-buttons';

$icon-width: 55px;

.notificationCard {
  background-color: $white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  color: $green-700;

  &.isMenu {
    border-bottom: 1px solid $gray-50;
    margin-bottom: $spacer-xs;
  }

  &.notMenu {
    border: 1px solid $gray-100;
    border-radius: 3px;
    box-shadow: 1px 1px 4px rgb(0 0 0 / 14%);
    margin-bottom: $spacer-sm;
    padding: $spacer-sm;
  }

  &.unreadNotification {
    background-color: $green-50;
  }

  &:hover {
    text-decoration: none;
    color: $green-700;
    background-color: $green-100;
  }
}

.content {
  display: grid;
  grid-template-columns: $icon-width 1fr;
  gap: $spacer-sm;
  align-items: center;
  padding: $spacer-sm;
}

.title {
  font-weight: bold;
  white-space: normal;
  font-size: $font-size-sm;
  margin-bottom: $spacer-xs;

  :local(.notMenu) & {
    font-size: $font-size-base;
    margin-bottom: $spacer-sm;
  }
}

.body {
  color: $gray-500;
  margin: 0;
  font-size: $font-size-sm;
}

.notificationIcon {
  width: $icon-width;
  text-align: center;
  color: $green-300;

  :local(.unreadNotification) & {
    color: darken($green-300, 10%);
  }
  :local(.notificationCard):hover & {
    color: $green-700;
  }
}

.filterSection {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: $spacer-lg;
  gap: $spacer-sm;
  // "d-flex flex-wrap justify-content-between align-items-end pb-3"
}

.categoriesWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: $spacer-sm;
}

.categoryButton {
  @extend .btn;
  @extend .btn-sm;
  color: rgba($green-500, 0.75);
  display: inline-flex;
  gap: $spacer-sm;
  justify-content: center;
  align-items: center;
  padding: $spacer-xs $spacer;
  font-weight: 700;
  border: 0;
  border-bottom: 2px solid rgba($green-500, 0);
  box-shadow: $box-shadow-sm;
  background-color: $white;
  transition: border-bottom-color 0.3s, color 0.3s;
  &:active {
    background-color: rgba($green-50, 0.5) !important;
  }
  &:focus {
    background-color: $white;
    outline: none;
  }

  &.active {
    border-bottom-color: rgba($green-500, 1);
    color: $green-500;
  }
  &:hover {
    color: $green-500;
  }
}

.actionsWrapper {
  display: flex;
  gap: $spacer-sm;
}

.actionButton {
  @extend %actionButton;
}
.notificationAction {
  @extend %actionButton;
}
