@import 'src/assets/scss/buttons';

%actionButton {
  @extend .btn;
  @extend .btn-sm;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 31px;
  height: 31px;
  border: 0;
  transition: border-bottom-color 0.2s, color 0.2s;

  &.secondary {
    color: $green-500;
    background-color: $green-100;
    border-bottom: 2px solid rgba($green-900, 0);

    &:hover {
      color: $green-700;
      border-bottom-color: rgba($green-900, 1);
    }
  }
  &.primary {
    color: $red-700;
    background-color: $red-100;
    border-bottom: 2px solid rgba($red-900, 0);

    &:hover {
      color: $red-900;
      border-bottom-color: rgba($red-900, 1);
    }
  }

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
  }
}
