@import '../../assets/scss/_functions.scss';
@import '../../assets/scss/_variables.scss';
@import '../../assets/scss/_mixins.scss';

.list-item_specializations:not(:first-child) {
  border-left: solid 1px $gray-100;
  padding-left: 1.75rem;
}

.list-item_specializations {
  min-width: 50%;
}

.list-item_specializations__heading {
  font-size: 0.875rem;
  font-weight: normal;
  margin-bottom: 0;
}

.list-item_specializations__list {
  color: $gray-700;
  display: flex;
  flex-wrap: wrap;
  font-size: 0.875rem;
  font-weight: bold;
}

.list-item_specializations__item {
  white-space: nowrap;
}

.list-item_specializations__item:not(:last-child) {
  padding-right: 0.175rem;
}

.list-item_contact {
  justify-content: space-between;
  font-size: 0.875rem;
  margin-right: 1rem;
}

.list-item_contact__address {
  margin: 0 1.25rem 0 0;
}

.listItemDate {
  color: $red-500;
  display: flex;
  flex-flow: column;
  font-weight: bold;
  margin-right: 1.25rem;
  min-width: 2.75rem;
  padding-top: 0.25rem;
  text-align: center;
}

.listItemDate.past {
  color: $gray-400;
}

.listItemDateDay {
  font-size: 1.5rem;
  line-height: 1.5rem;
}

.listItemDateMonth {
  text-transform: uppercase;
}

.listItemDetails {
  font-size: 0.875rem;
  line-height: 1.2;
}

.listItemDetails:before {
  content: '-';
  display: inline;
  margin: 0 0.375rem;
}

.listItemDescription {
  font-size: 0.875rem;
  line-height: 1.14rem;
  max-height: 2.28rem;
  max-width: 25rem;
  overflow: hidden;
  width: 100%;
}

.listItemDescription {
  p {
    margin: 0;
  }
}

.listItemDescription:not(:last-child) {
  margin-right: 2rem;
}

.listItemMobileLink {
  border: none;
  padding-top: 0;
  padding-bottom: 0;
  background-color: $white;
  &:focus {
    outline: none;
  }
}

.listItemLink {
  align-items: center;
  align-self: center;
  background-color: $gray-50;
  border: 2px solid $gray-50;
  border-radius: 50%;
  display: flex;
  height: 2.5rem;
  justify-content: center;
  width: 2.5rem;

  i {
    color: $gray-400;
    height: 50%;
    padding-left: 0.125rem;
  }
}

.listItemLink:focus {
  background-color: $red-50;
  border-color: $red-500;
  outline: none;

  i {
    color: $red-700;
  }
}

.listItemTitle {
  color: $gray-700;
}

.listItemTitleWrapper {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0;
}

.listItemTruncationWrapper {
  min-width: 0;

  div {
    min-width: 0;
  }
}

.listItemWikiIcon {
  color: #a6aeaf;
  padding: 0.5rem 2rem 0 0.25rem;
}

.listItemWrapper {
  border: none;
  border-radius: 0.375rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  min-height: calc(2.5rem + 2.5rem + 1.25rem);
  padding: 1rem 1.25rem;
}

.listItemWrapper:not(:last-child) {
  margin-bottom: 0.5rem;
}

.listItemWrapper:hover {
  .listItemLink {
    background-color: $red-50;
    border-color: $red-50;

    i {
      color: $red-700;
    }
  }

  .listItemLink:active {
    background-color: $red-500;
    border-color: $red-500;

    i {
      color: $white;
    }
  }
}

.listItemLink.activeListItem {
  background-color: $red-500;
  border-color: $red-500;

  i {
    color: $white;
  }
}

.draft {
  position: absolute;
  right: 0;
}
