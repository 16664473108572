@import 'src/assets/scss/_functions.scss';
@import 'src/assets/scss/_variables.scss';
@import 'src/assets/scss/_mixins.scss';

.mainLayoutContent {
  margin: -24px auto;
  padding: $spacer-xl $spacer 0;

  @include media-breakpoint-up(lg) {
    padding-top: 0;
    padding: $spacer-xl 60px 0;
  }
}

.loadingLogo {
  margin: $spacer auto;
  display: block;
  width: 127px;
  @include media-breakpoint-up(lg) {
    margin: $spacer-sm auto;
    width: 144px;
  }
  @include media-breakpoint-up(xl) {
    margin: $spacer 0 0 -#{$spacer-sm};
  }
}
