@import '../../../../assets/scss/_functions.scss';
@import '../../../../assets/scss/_variables.scss';
@import '../../../../assets/scss/_mixins.scss';

.reportYearWrapper {
  margin-bottom: 0.75rem;
  overflow: hidden;
}

.reportYearWrapper:not(:first-child) {
  margin-top: 1.5rem;
}

.reportYearYear {
  color: $gray-500;
  display: inline-block;
  font-size: 0.875rem;
  font-weight: bold;
  position: relative;
}

.reportYearYear:after {
  background-color: $gray-200;
  content: '';
  display: inline-block;
  height: 1px;
  position: absolute;
  width: 2000px;
  left: 100%;
  margin-left: 0.75rem;
  top: 50%;
}
