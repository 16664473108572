@import 'src/assets/scss/_functions.scss';
@import 'src/assets/scss/_variables.scss';

// TODO: fix bootstrap classes and make modular

.form-group {
  padding: 0 24px;
}

// label {
//   color: $gray-700;
//   font-weight: 600;
//   margin-bottom: 4px;
//   font-size: 0.875rem;
// }

// input {
//   &.form-control {
//     border-color: $gray-100;
//     background-color: $gray-50;
//   }
// }

.formikButton {
  &[type='submit'] {
    outline: none;
    background-color: $red-500;
    border: none;
    border-radius: 0;
    padding: 12px;
    font-weight: 600;
    color: white;
    transition: all 300ms ease-in-out;
    border: 2px solid transparent;
  }

  &:hover {
    background-color: $red-700;
  }

  &:focus {
    border: 2px solid $red-700;
  }
}
