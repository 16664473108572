@import 'src/assets/scss/_functions.scss';
@import 'src/assets/scss/_variables.scss';
@import 'src/assets/scss/_mixins.scss';
@import 'src/assets/scss/buttons';

.menuLink {
  padding: 0.5rem 1rem;
  border-left: 0.25rem solid transparent;
  color: $green-500;
  display: block;
  outline: none;
  text-decoration: none;
  &:hover,
  &:focus {
    border-color: $red-500;
    font-weight: 600;
    color: $gray-700;
    background-color: $gray-100;
  }
  &:active,
  &.active {
    border-left: 0.25rem solid $red-500;
    background-color: $gray-50;
    font-weight: 600;
    color: $gray-700;
  }
}

.mainNavCompact {
  position: relative;
  background-color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 0;

  .mobileMenuTrigger {
    @extend .btn;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: $spacer-xs;
    position: absolute;
    left: 0px;
    font-weight: 600;
    letter-spacing: 0.2px;
    i {
      color: $gray-500;
    }
  }

  .logoWrapper {
    img {
      width: 127px;
    }
  }
}

$menu-width: 286px;
.mobileMenuWrapper {
  position: fixed;
  top: 0;
  left: -#{$menu-width};
  width: $menu-width;
  max-width: 100%;
  height: 100vh;
  z-index: 2001;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $white;
  transition: left 0.3s;
  padding-bottom: 14px;

  .mobileMenuHeader {
    position: sticky;
    top: 0;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $spacer;
    margin-bottom: 20px;

    .logoWrapper {
      img {
        width: 127px;
      }
    }
  }

  &.isOpen {
    left: 0;
  }
}

.logoutBtn {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
  margin: 1rem;
  text-align: center;
  font-weight: 700;
  display: block;
  color: $red-500;
  background-color: white;
  border: 2px solid transparent;
  outline: none;
  text-decoration: none;
  border-radius: 3px;
  width: calc(100% - 2rem);

  &:hover {
    color: $red-700;
    background-color: $red-50;
    border-color: transparent;
  }
  &:focus {
    color: $red-700;
    background-color: $red-50;
    border-color: $red-500;
    outline: none;
  }
  &:active {
    color: white;
    background-color: $red-500;
    border-color: transparent;
  }
}
