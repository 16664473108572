@import '../../../assets/scss/functions.scss';
@import '../../../assets/scss/variables';

$spinnerHeight: 2rem;
$spinnerWidth: 2rem;

.centeredSpinner {
  position: absolute;
  width: $spinnerHeight;
  height: $spinnerWidth;
  top: calc(50% - (#{$spinnerHeight} / 2));
  right: calc(50% - (#{$spinnerWidth} / 2));
  &.red {
    color: $red-500;
  }
  &.green {
    color: $green-300;
  }
  &.yellow {
    color: $yellow-500;
  }
  &.white {
    color: $white;
  }
}

.message {
  text-align: center;
  position: absolute;
  z-index: 11;
  top: calc(50% + #{$spinnerHeight});
  width: 100%;
  display: block;

  &.red {
    color: $red-500;
  }
  &.green {
    color: $green-300;
  }
  &.yellow {
    color: $yellow-500;
  }
}

.displayBlock {
  text-align: center;
  position: relative;
  display: block;
  margin: 5rem 0;
  width: 100%;
  height: 100%;
  padding-top: $spinner-height * 4;
}
