@import 'src/assets/scss/_functions.scss';
@import 'src/assets/scss/_variables.scss';
@import 'src/assets/scss/_mixins.scss';

.languageList {
  margin: 0;
  list-style: none;
  opacity: 1;
  background-color: $gray-25;
  padding: $spacer-sm 0;

  &:active {
    background-color: $gray-50;
  }

  @include media-breakpoint-up(lg) {
    opacity: 0;
    right: -1000%;
    position: absolute;
    top: $spacer-xs;
    border-radius: 3px;
    transition: opacity 0.1s;
    box-shadow: 0 0.5rem 1rem rgb(0, 0, 0, 15%);
  }
}

:global(.languageSwitcher) {
  @include media-breakpoint-up(lg) {
    &:hover .languageList {
      opacity: 1;
      right: 98%;
    }
  }
}

.listItemButton {
  padding: $spacer-xs $spacer;
  display: grid;
  width: 100%;
  text-align: left;
  align-items: center;
  grid-template-columns: 20px 1fr;
  gap: $spacer-xs;
  border: 0;
  background: transparent;
  color: $gray-600;

  .flagIcon {
    filter: grayscale(0.5);
    text-align: center;
  }

  &:hover {
    .flagIcon {
      filter: grayscale(0);
    }
  }

  &:hover:not(:local(.selected)) {
    background-color: $gray-75;
    color: $black;

    &:active {
      background-color: $gray-100;
    }
    .flagIcon {
      filter: grayscale(0);
    }
  }

  &.selected {
    background-color: transparent;
    font-weight: 700;
    cursor: default;
    color: $black;

    .flagIcon {
      filter: grayscale(0);
    }
  }

  &:active,
  &:focus {
    outline: none;
  }
}
