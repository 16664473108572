@import 'src/assets/scss/_functions.scss';
@import 'src/assets/scss/_variables.scss';
@import 'src/assets/scss/_mixins.scss';

.cardContainer {
  border: none;
  border-radius: $border-radius-lg;
  box-shadow: $box-shadow-sm;
  overflow: hidden;
  margin-bottom: 0;
  height: 100%;

  .imgContainer {
    margin-bottom: 0;
    text-align: center;
  }

  .coverImg {
    background-position-x: left;
    background-position-y: top;
    background-repeat: no-repeat;
    background-size: 100%;
    height: 0;
    padding-top: 56.25%;
  }

  .coverVideo {
    border-top-left-radius: $border-radius-lg;
    border-top-right-radius: $border-radius-lg;
    position: relative;
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .video-description {
    padding: $spacer-sm $spacer-md 0;
    text-align: left;

    p {
      margin-bottom: 0;
    }

    .video-description_title {
      font-weight: bold;
    }
  }

  .card-body img {
    max-width: 100%;
  }

  .cardDetail {
    h1 {
      font-size: $h2-font-size;
    }

    h2 {
      font-size: $h3-font-size;
    }

    h3 {
      font-size: $h4-font-size;
    }
  }

  border-radius: 0;

  @include media-breakpoint-up(md) {
    border-radius: $border-radius-lg;
    margin-bottom: 2rem;
  }
}
