@import 'src/assets/scss/_functions.scss';
@import 'src/assets/scss/_variables.scss';
@import 'src/assets/scss/_mixins.scss';
@import 'src/assets/scss/buttons';

.mobileNotification {
  @extend .btn;
  position: absolute;
  right: 0px;
}
.notificationBubble {
  position: absolute;
  top: 10px;
  right: 9px;
  padding: 0;
  border-radius: 50%;
  background: $red-500;
  border: 1px solid $white;
  width: 8px;
  height: 8px;
}
