@import 'src/assets/scss/functions';
@import 'src/assets/scss/variables';
@import 'src/assets/scss/mixins';
@import 'src/assets/scss/buttons';

.nextStep {
  padding-top: $spacer;
  display: flex;
  gap: $spacer;
  flex-direction: column;
  @include media-breakpoint-up(sm) {
    text-align: center;
  }
  @include media-breakpoint-up(md) {
    flex-direction: row;
    text-align: left;
    justify-content: space-between;
    align-items: center;
  }
}
.stepButton {
  @extend .btn;
  @extend .btn-red-500;
  white-space: nowrap;
  width: 100%;

  @include media-breakpoint-up(sm) {
    width: auto;
    margin: 0 auto;
    display: block;
  }

  &:disabled {
    opacity: 0.7;
  }
}
.nextStepDescription {
  margin: 0;
  font-size: $font-size-sm;
  font-weight: bold;
}
