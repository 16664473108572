@import '../../assets/scss/_functions.scss';
@import '../../assets/scss/_variables.scss';
@import '../../assets/scss/_mixins.scss';

.loadMoreButton {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
  text-align: center;
  font-weight: 700;
  display: block;
  color: $gray-500;
  background-color: white;
  border: 2px solid white;
  border-radius: 3px;
  padding: 0 1rem;
}

.loadMoreButton:hover {
  color: $red-700;
  background-color: $red-50;
  border-color: $red-50;
}

.loadMoreButton:focus {
  color: $red-700;
  background-color: $red-50;
  border-color: $red-500;
  outline: none;
}

.loadMoreButton:active {
  color: white;
  background-color: $red-500;
  border-color: $red-500;
}
