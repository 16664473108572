//
// Rotating border
//

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: $spinner-width;
  height: $spinner-height;
  vertical-align: text-bottom;
  border: $spinner-border-width solid currentColor;
  border-right-color: transparent;
  // stylelint-disable-next-line property-blacklist
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: $spinner-width-sm;
  height: $spinner-height-sm;
  border-width: $spinner-border-width-sm;
}

//
// Growing circle
//

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}

.spinner-grow {
  display: inline-block;
  width: $spinner-width;
  height: $spinner-height;
  vertical-align: text-bottom;
  background-color: currentColor;
  // stylelint-disable-next-line property-blacklist
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
  width: $spinner-width-sm;
  height: $spinner-height-sm;
}

@keyframes spinner-rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner-wrapper {
  display: block;
  position: fixed;
  right: 15px;
  top: 15px;
  z-index: 1031;
  height: 22px;
  text-align: center;
  .spinner {
    animation: 1s linear infinite spinner-rotation;
    border-bottom: 2px solid transparent;
    border-left: 2px solid $red-500;
    border-radius: 50%;
    border-right: 2px solid transparent;
    border-top: 2px solid $red-500;
    box-sizing: border-box;
    height: 22px;
    width: 22px;
  }
  img {
    position: relative;
    bottom: 25px;
  }
}
