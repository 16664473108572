@import 'src/assets/scss/_layout.scss';

.cardContainer {
  @extend .cardContainer;
}

.adjustSnackbarPosition {
  top: 80px !important;
}

.content img {
  max-width: 100%;
}
