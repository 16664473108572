@import '../../../../assets/scss/functions';
@import '../../../../assets/scss/variables';
@import '../../../../assets/scss/mixins/';

.eventsWrapper {
  min-height: 17.375rem;
  border-radius: 0.375rem;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: $white;
}

.eventsDashboardWrapper {
  min-height: auto;
  border-radius: 0%;
}

.eventDetailContainer {
  width: 100%;
  min-height: 3.5rem;
  background-color: var(--gray-50);
}
.eventDate {
  max-width: 7.312rem;
}
.event {
  width: 100%;
  justify-content: space-between;
}

.eventItemDescription {
  p {
    font-size: 0.875rem;
  }
}

.eventDetails {
  min-height: 3.5rem;
  overflow: hidden;
  letter-spacing: normal;
  color: var(--gray-700);
  .eventDetailHeading {
    font-size: 0.875rem;
    font-weight: bold;
  }
  p {
    margin-bottom: 0rem;
    font-size: 0.875rem;
  }

  a {
    color: var(--red-500);
  }
}
.contactTileSection {
  min-width: 18.125rem;
  max-width: 25rem;
}
.contacts {
  font-size: 0.875rem;
  font-weight: bold;
  color: var(--gray-700);
}
.eventItemWrapper {
  min-height: 8.6875rem;
  position: relative;
  .eventItemTitle {
    color: $black;
    font-weight: bold;
    font-size: 1.125rem;
    margin-bottom: 0.5rem;
  }
  .eventDashboardTitle {
    color: $gray-700;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  p {
    color: var(--gray-500);
  }
}

// Event Date Atom
.eventDateContainer {
  text-align: center;
  min-height: 5.5rem;
  min-width: 5.5rem;
  font-weight: bold;
  border-radius: 3px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: $white;
}
.month {
  overflow: hidden;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  height: 1.312;
  width: 100%;
  background-color: var(--red-500);
  color: $white;
  font-size: 1rem;
  margin: 0rem;
}
.date {
  width: 100%;
  font-size: 2rem;
  margin: 0rem;
  color: var(--gray-700);
}
.day {
  font-size: 0.875rem;
  margin: 0rem;
  color: var(--gray-700);
}
