@import 'src/assets/scss/_functions.scss';
@import 'src/assets/scss/_variables.scss';
@import 'src/assets/scss/_mixins.scss';

.main {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.maintenance {
  display: flex;
  margin: $spacer auto;
  padding: $spacer-lg $spacer;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: $white;
  box-shadow: $box-shadow;
  border-radius: $border-radius;
  width: 500px;
  max-width: calc(100vw - #{2 * $spacer});
}
.image {
  width: 300px;
  max-width: 80%;
  aspect-ratio: 1 / 1;
}

.body {
  max-width: 300px;
  white-space: pre-line;
}

.logo {
  margin: $spacer-sm 0 $spacer-lg;
}
