@import 'src/assets/scss/_functions.scss';
@import 'src/assets/scss/_variables.scss';
@import 'src/assets/scss/_mixins.scss';
@import 'src/assets/scss/buttons';

.navMenuWrapper {
  display: block;
  @include media-breakpoint-up(lg) {
    display: flex;
    position: sticky;
    top: 0;
    z-index: 2;
  }
  @include media-breakpoint-up(xl) {
    padding-bottom: $spacer-xl;
  }
}

.dropdown {
  color: $green-500;
  font-weight: 600;

  .hideCaret::before,
  .hideCaret::after,
  &.hideCaret::before,
  &.hideCaret::after {
    display: none;
  }
  &:hover .dropdownMenu {
    display: block;
    margin-top: 0;
  }
}

.dropdownItem {
  width: 100%;
  display: block;
  font-weight: normal;
  font-size: $font-size-sm;
  padding: 0.25rem 1rem;
  &:hover,
  &:hover a {
    background: $gray-50;
    color: $black;
  }
  &:active,
  & a:active {
    background: $gray-100;
  }
  .noOutline {
    outline: none;
    border: 0;
    background: transparent;
  }
}

.userMenuLabel {
  display: grid;
  grid-template-columns: 20px 1fr;
  align-items: center;
  gap: $spacer-sm;
  text-align: left;
  justify-content: center;
  padding: 0;
  width: 100%;
  text-decoration: none;
  color: $gray-600;
  i {
    text-align: center;
  }
}

.WrapText,
.changeFontSize {
  font-size: $font-size-sm;
}

.notificatinButton {
  @extend .btn;
  @extend .changeFontSize;
  @extend .dropdown;
  display: block;
}

.notification {
  position: absolute;
  top: 11px;
  right: 14px;
  padding: 0;
  border-radius: 50%;
  background: $red-500;
  border: 1px solid $white;
  width: 8px;
  height: 8px;
}

.notificationMenu {
  width: 350px;
}

@include media-breakpoint-down(lg) {
  .WrapText {
    max-width: 150px;
  }
}
