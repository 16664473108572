@import '../../assets/scss/_functions.scss';
@import '../../assets/scss/_variables.scss';
@import '../../assets/scss/_mixins.scss';

.searchBarIcon {
  color: $gray-200;
  position: absolute;
  left: 0.75rem;
  top: 0.75rem;
}
.searchBarWrapper {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  border-radius: 0.375rem;
}
.searchBarInput {
  background-clip: padding-box;
  background-color: white;
  border: solid 2px white;
  border-radius: 0.375rem;
  color: $gray-700;
  height: calc(1.5em + 0.75rem + 4px);
  padding: 0.375rem 0.75rem 0.375rem 2.25rem;
  width: 100%;
}

.searchBarInput::placeholder {
  color: $gray-500;
}

.searchBarInput:focus {
  background-color: white;
  outline: none;
}

.searchClearButton {
  color: $gray-300;
  background-color: transparent;
  border: none;
  position: absolute;
  right: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
}
